
import {
  computed,
  defineComponent,
  toRefs,
  reactive,
  watch,
} from 'vue';
import { useMutation } from 'villus';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();

    const variables: any = reactive({
      title: '',
      body: '',
      publishAtDate: '',
      publishAtTime: '09:00:00',
      publishAt: computed(() => `${variables.publishAtDate} ${variables.publishAtTime}`),
    });

    const NewPost = `
      mutation CreatePost($title: String!,
                          $body: String!,
                          $publishAt: String!) {
        CreatePost(title: $title,
                   body: $body,
                   publishAt: $publishAt) {
          id
        }
      }
    `;

    const { data, execute } = useMutation(NewPost);
    const submit = () => execute(variables);

    watch(data, () => {
      router.replace({ name: 'Posts' });
    });

    return { submit, ...toRefs(variables) };
  },
});
